import md5 from 'js-md5'

export function buildParam (params, key) {
	let keyList = key || []
	const x = JSON.parse(JSON.stringify(params));

	Object.keys(params).forEach((e) => {
		if (keyList.includes(e)) delete params[e];
	});
	const query = JSON.stringify(x);
	params.queryStr = query;
}
//比较对象是否相同
export function isObjectEqual (obj1, obj2) {
	const obj1Keys = Object.keys(obj1);
	const obj2Keys = Object.keys(obj2);

	if (obj1Keys.length != obj2Keys.length) {
		return false;
	}

	for (let key of obj1Keys) {
		if (obj1[key] != obj2[key]) {
			return false;
		}
	}

	return true;
}

export function generateUniappNonce () {
	// debugger
	let currentStamp = new Date().getTime();
	//uniapp登录专用密钥，格式固定
	let encryptStr = "|2024|XHGONGDIBAO|UNIAPP|WX|2024|MINI|XHGONGDIBAO|2024|"
	let uniappNonce = md5(currentStamp + encryptStr + currentStamp) + currentStamp
	return uniappNonce;
}